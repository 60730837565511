@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
    font-family: 'Montserrat';
    background-size: cover;
}

@media screen and (max-width: 1024px) {
    
    body {
        background: #1B2020;
    }
}

.c-select {
    appearance: none; /* Modern browsers */
      -webkit-appearance: none; /* Safari and Chrome */
      -moz-appearance: none; /* Firefox */
    background: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="11" height="11" viewBox="0 0 11 11" fill="none"%3E%3Cpath d="M5.50052 6.86353C5.43941 6.86353 5.38013 6.85208 5.32269 6.82916C5.26524 6.80624 5.21758 6.77569 5.17969 6.73749L3.07135 4.62916C2.98733 4.54513 2.94531 4.43819 2.94531 4.30832C2.94531 4.17846 2.98733 4.07152 3.07135 3.98749C3.15538 3.90346 3.26233 3.86145 3.39219 3.86145C3.52205 3.86145 3.62899 3.90346 3.71302 3.98749L5.50052 5.77499L7.28802 3.98749C7.37205 3.90346 7.47899 3.86145 7.60885 3.86145C7.73872 3.86145 7.84566 3.90346 7.92969 3.98749C8.01371 4.07152 8.05573 4.17846 8.05573 4.30832C8.05573 4.43819 8.01371 4.54513 7.92969 4.62916L5.82135 6.73749C5.77552 6.78333 5.72587 6.81587 5.6724 6.83512C5.61892 6.85437 5.56163 6.86384 5.50052 6.86353Z" fill="white"/%3E%3C/svg%3E') no-repeat right 10px center;
    background-size: 16px 16px;
    background-position: 23px 5px;
    padding-right: 20px;
}

.custom-select {
    position: relative;
    width: auto;
}
  
.select-selected {
cursor: pointer;
}

.select-options {
display: none;
position: absolute;
background: white;
z-index: 1000;
list-style: none;
margin: 0;
padding: 0;
}

.select-options li {
padding: 10px;
cursor: pointer;
}

.select-options li:hover {
background: #e0e0e0;
}

.custom-select.active .select-options {
display: block;
}

.c-select {
    display: none;
}

.text-gradient-right {
    background: linear-gradient(90deg, #FFFFFF 49.64%, rgba(255, 255, 255, 0) 95.17%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.btn-lilic {
    @apply bg-lilac rounded-[60px] min-w-[273px] p-3 text-center;
}

.btn-lilic-2 {
    @apply p-[33px] max-w-[441px] w-full text-center bg-lilac rounded-full;
}

.btn-transparent {
    @apply border-[1px] border-light-green rounded-[60px] min-w-[273px] p-3 text-center;
}

.btn-transparent-2 {
    @apply border-[1px] border-light-green text-center;
}

.slide-1 {
    background: linear-gradient(102.67deg, #15282d -28.15%, #1a2e33 29.26%, #192d32 102.47%, #B1CC0A 133.78%), #192d32;
}

.slide-2 {
    background: linear-gradient(102.67deg, #734ED1 -28.15%, #291D41 29.26%, #22210F 102.47%, #B1CC0A 133.78%), #172021;
}

#main-slider .swiper-pagination {
    @apply !w-auto !bottom-[43px] !left-auto right-[15px];
}

.swiper-pagination-bullet {
    @apply !m-0 w-[40px] h-[6px] bg-[#D9D9D9] rounded-[10px] !opacity-100;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    @apply bg-[#734ED1];
}

.shadow-c-1 {
    box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.25);
}
.menu-header-content {
    @apply hidden;
}
.menu-header-content.active {
    @apply grid;
}

.modal {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 100%;
opacity: 0;
pointer-events: none;
transition: opacity 0.3s ease;
}
.modal .over-bg {
position: absolute;
top: 0;
left: 0;
right: 0;
bottom: 0;
background: rgba(40, 40, 40, 0.2);
backdrop-filter: blur(15px);
}

.modal.active {
opacity: 1;
pointer-events: auto;
}
.modal-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

/* Стилизация всего скроллбара */
::-webkit-scrollbar {
width: 14px; /* ширина скроллбара */
height: 96px; /* высота скроллбара (для горизонтального) */
}

/* Стилизация дорожки (фон) */
::-webkit-scrollbar-track {
background: rgba(23, 32, 33, 1); /* цвет фона дорожки */
}

/* Стилизация ползунка */
::-webkit-scrollbar-thumb {
background: #00F0FF; /* цвет ползунка */
border-radius: 6px; /* скругление */
}

/* Стилизация при наведении */
::-webkit-scrollbar-thumb:hover {
background: #0199a4; /* цвет при наведении */
}

#feature-slider .swiper-slide {
    height: 100%;
}

.placeholder-bold-uppercase::placeholder {
    @apply font-bold uppercase text-white;
}

.text-casual-shadow {
    text-shadow: 0px 4px 20px rgba(71, 195, 210, 0.5);
}

.container {
    @apply lg:px-[20px];
}

.rect-opacity:hover .rect {
    fill-opacity: 1;
}

.group-social:hover .group-social-hover {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(259deg) brightness(102%) contrast(101%);
}

.left-menu .group:hover img {
    filter: brightness(0) saturate(100%) invert(70%) sepia(54%) saturate(1066%) hue-rotate(131deg) brightness(100%) contrast(104%);
}

.left-menu .group.active img {
    filter: brightness(0) saturate(100%) invert(70%) sepia(54%) saturate(1066%) hue-rotate(131deg) brightness(100%) contrast(104%);
}

.group-chat:hover .group-hover {
    filter: brightness(0) saturate(100%) invert(70%) sepia(54%) saturate(1066%) hue-rotate(131deg) brightness(100%) contrast(104%);
}

header.fixed-area {
    padding-top: 25px!important;
    padding-bottom: 25px!important;
    @apply bg-light-black;
}

.slide-mask {
    -webkit-mask-image: url('../images/sl-1-m.png');
    mask-image: url('../images/sl-1-m.png');
    -webkit-mask-size: cover;
    mask-size: cover;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
}

#parallax-element-1 {
    position: absolute;
    margin-top: 579px;
    margin-left: -287.74px;
}

#parallax-element-2 {
    position: absolute;
    margin-top: 652px;
    margin-left: 955px;
}

#parallax-element-3 {
    position: absolute;
    margin-top: 600px;
    margin-left: 120px;
}

#parallax-element-4 {
    position: absolute;
    margin-top: 1033px;
    margin-left: 576px;
}

#parallax-element-5 {
    position: absolute;
    margin-top: 68px;
    margin-left: 857px;
}

#parallax-element-6 {
    position: absolute;
    margin-top: 10px;
    margin-left: 324px;
}

#parallax-element-7 {
    position: absolute;
    margin-top: 276px;
    margin-left: 557px;
}

#parallax-element-8 {
    position: absolute;
    margin-top: 335px;
    margin-left: -2px;
}

#parallax-element-9 {
    position: absolute;
    margin-top: 50px;
    margin-left: 246px;
}

@media (min-width: 767px) {
    .case-list .group img:last-child {
        @apply opacity-0;
    }

    .case-list .group:hover img:last-child {
        @apply opacity-100 w-full h-full object-cover z-10 transition duration-500;
    }  
}



@media (max-width: 767px) {
    .fade-element {
      opacity: 0;
      transition: opacity .3s ease-in-out .5s;
    }
    
    .fade-element.visible {
      opacity: 1;
    }  
  }